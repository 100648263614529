.hero {
  padding: .5em;
  text-align: center;
  border-radius: 8px;
}

.hero h1 {
  margin: 0;
  font-size: 3rem;
  color: var(--primary-color);
}