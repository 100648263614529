.quote-container {
  position: relative; 
  max-width: 75%; /* Limit width to ensure responsiveness */
  margin: auto;

  
}

.quote-card {
  position: absolute;
  border: 2px solid #ccc; /* Border for the quote card */
  padding: 20px; /* Padding around the quote */
  width: 100%;
  transition: transform 0.5s ease; 
  background-color:var(--background-color);
  
 
}

.quote {
  font-size: 18px;
  line-height: 1.5;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;

}

.nav-button {
  background-color: var(--primary-color);
  color: #fff;
  font-family: var(--header-font);
  font-weight: bold;
  font-size: 1.5em;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 5px;
  
}
.button-h2{
  padding: 15px;
 
}

.nav-button:hover {
  background-color: var(--hover-color);
}

.card-hidden {
  opacity: 0;
  pointer-events: none; /* Disable pointer events to prevent interaction */
}

.card-visible {
  opacity: 1;
  pointer-events: auto; /* Re-enable pointer events */
   margin: auto;
}

@media (max-width: 600px) {

}