:root {
  --primary-color: #044777;
  --secondary-color: #DE6135;
  --accent-color: #9CAFB7;
  --hover-color: #0056b3;
  --background-color:rgba(189,239,239,.6);
  --window-frame: #BDBEC3;
  /* --window-frame: brown; */
  /* --window-frame: #965654; */
  --text-color: #2c3e50;

  --header-font: 'Calibri', 'Arial', sans-serif;
  --body-font: 'Roboto', 'Arial', sans-serif;
}

h1, h2, h3, h4, h5, h6{
  font-family: var(--header-font);
  font-weight: bold;
}
body {
  margin: 0;
  font-family: var(--body-font);
  font-weight: 400;
  text-align: center;
}
.pic{
   border-radius: 10px;
}


/* .App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */