.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('../assets/nav-bar-water.png'); 
  background-size: cover; 
  background-position: center; 
  padding: 1rem;
  /* position: fixed; */
  width: 100%;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 999;
}

.footer-section {
  flex: 1;
  text-align: center;
  color: black;
  font-weight: bold;
}

.contact a{
  color: black;
  text-decoration: none;
}
.contact a:hover{
  text-decoration: underline;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-media .social-media-icon {
  width: 40px; 
  height: 40px; 
  margin: 0 10px; 
}


@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Stack items vertically */
    text-align: center; /* Center align content */
  }

  .footer-section {
    margin-bottom: 0;
  }

  .contact, .social-media {
    margin-top: 10px; 
  }
}