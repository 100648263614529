.navbar {
  height: 100px;
  display: flex;
  align-items: center;
  background-image: url('../assets/nav-bar-water.png');
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #B6B9BD;
  box-sizing: border-box;
  width: 100%;
}

.logo {
  height: 105px;
}

.logo-desktop {
  display: block;
}

.logo-mobile {
  display: none;
}

.menu-icon {
  font-size: 30px;
  cursor: pointer;
  margin-left: auto;
  display: none;
  padding: 0 1em;
}

.menu {
  display: flex;
  list-style: none;
  margin-left: auto;
  padding: 2em;
}

.menu li {
  padding: 0 2em;
  font-weight: bold;
}

.menu li a {
  color: black;
  text-decoration: none;
  font-size: 18px;
}

.menu li a:hover {
  text-decoration: underline;
}

/* Responsive styling for smaller screens */
@media (max-width: 810px) {
  .menu-icon {
    display: block;
  }

  .menu {
    display: none; /* Hide the menu by default */
    flex-direction: column;
    width: 100%;
    background-color: #B6B9BD;
    position: absolute;
    top: 100px; /* Position below the navbar */
    left: 0;
  }

  .menu.open {
    display: flex;
  }

  .menu li {
    padding: 1em 0.5em;
    text-align: center; /* Center align the menu items */
  }

  .logo {
    left: 0;
  }
}

@media (max-width:450px){
     .logo-desktop {
    display: none;
  }

  .logo-mobile {
    display: block;
    
  }
}