.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  grid-template-rows: auto auto; /* Adjust rows as needed */
  gap: 20px; /* Space between grid items */
  width: 100%; /* Ensure the grid container takes full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Grid Item Styles */
.grid-item {
  padding: 10px;
  box-sizing: border-box; /* Ensure padding is included in width/height calculations */

}

/* Grid Item Big Styles */
.grid-item-big {
  grid-row: span 2; /* Takes up two rows */
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item-big img,
.grid-item img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any extra space below the image */
  max-width: 100%; /* Ensure image does not exceed container width */
}
.intro{
    border: 2px solid var(--accent-color);
  border-radius: 8px;
  background-color:var(--background-color);
  padding: 2em;
}

.testimonials{
  margin-bottom: 18em;
  

}

@media (max-width: 1200px){
  .family {
    display: none; /* Hide the bottom item on small screens */
  }
}
/* Responsive Design */
@media (max-width: 750px) {
  .grid-container {
    grid-template-columns: 1fr; /* One column for small screens */
    grid-template-rows: auto auto; /* Adjust rows as needed */
  }
    .family {
    display: block; /* Hide the bottom item on small screens */
  }
  .testimonials{
    margin-bottom: 30em;
  }


}