.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
}

.content {
  flex: 1;
  width: 80%;
  margin: auto;

  transition: margin-top 0.3s ease; /* Smooth transition for margin changes */
}

.menu-open .content {
  margin-top: 300px; /* Adjust based on the height of the open menu */
}

/* Responsive styles */
@media (max-width: 480px) {
  .content {
    width: 95%;
    padding-bottom: 10em;
  }
}
