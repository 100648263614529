.grid-container-home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
  border: 10px solid var(--window-frame);
  background-color: var(--window-frame);
  border-radius: 20px;
}

/* Style the grid items */
.grid-item-home {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--window-frame);
  padding: 5px;
}

/* Ensure images are responsive */
.pic {
  max-width: 100%;
  height: auto;
}



.responsive-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; /* Add some gap between items */
  margin: 2em auto;
  max-width: 800px;
}

.responsive-item {
  flex: 1 1 calc(50% - 20px); /* Take 50% width minus the gap */
  box-sizing: border-box;
    padding: 1em;
  background-color: var(--background-color);
    border: 2px solid var(--accent-color);
  border-radius: 8px;
}

.services-p,
.schedule-p {
  margin: 0;
}

.nav-button {
  margin-top: 1em;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .responsive-item {
    flex: 1 1 100%; /* Take full width on smaller screens */
  }
}


/* Responsive design for small screens */
@media (max-width: 600px) {
  .grid-container-home {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

    .extra-pic {
    display: none;
  }

  .grid-container-home {
    grid-template-columns: 1fr; /* Single column layout for the remaining items */
  }
}


